import React, { Component } from 'react'
import storyline from '../utils/matrix_storyline'
import Typed from 'react-typed'
import MatrixRain from './MatrixRain'

class MatrixProgram extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current_prompt: storyline[0],
      history: [],
      show_options: false,
      show_morpheus: true,
      show_terminal: true,
      timer: 7,
      username: '',
      reset: false,
      typingIndicator: false,
    }
  }

  componentDidMount() {
    let URL = window.location.href
    let URLend = URL.slice(URL.indexOf('matrix') + 7)
    let name = URLend.split('/')
    let URLName = name[0]
    if (URLName === '') {
      URLName = 'Neo'
    }
    let testObj = storyline[0]
    if (this.state.current_prompt == storyline[0]) {
      testObj.morpheus_text = `Wake up, ${URLName[0].toUpperCase() +
        URLName.slice(1)}... The Matrix has you...`
      this.setState({
        current_prompt: testObj,
        username: URLName,
      })
    }

    document.addEventListener('keydown', this.onKeyChoice.bind(this))
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyChoice.bind(this))
  }
  // Choose an option
  onKeyChoice(e) {
    switch (e.keyCode) {
      // A key
      case 65:
        if (this.state.current_prompt.options[0]) {
          this.selectOption(0)
        }
        break
      // B key
      case 66:
        if (this.state.current_prompt.options[1]) {
          this.selectOption(1)
        }
        break
      // C key
      case 67:
        if (this.state.current_prompt.options[2]) {
          this.selectOption(2)
        }
        break
      // D key
      case 68:
        if (this.state.current_prompt.options[3]) {
          this.selectOption(3)
        }
        break
      default:
        break
    }
  }

  // Change prompt and log the previous text
  selectOption(i) {
    this.changePrompt(this.state.current_prompt.options[i])
  }

  // Reset the matrix
  resetProgram() {
    this.setState({
      current_prompt: storyline['reset'],
      show_options: false,
      history: [],
      show_morpheus: false,
      timer: 7,
      reset: true,
    })
  }
  // Wait until typing is complete then show options
  onCompleteTyped = () => {
    this.setState({
      show_options: true,
    })
  }

  // Open and Close buttons
  openMatrixTerminal() {
    if (this.state.reset) {
      this.setState({
        reset: false,
      })
      this.changePrompt(this.state.current_prompt.options[0])
    }
    this.setState({
      show_terminal: true,
    })
  }

  closeMatrixTerminal(reset_program) {
    if (reset_program) {
      this.resetProgram()
    }
    this.setState({
      show_terminal: false,
    })
  }

  makeMatrixTerminalFullscreen() {}

  // Update the story line
  changePrompt(Option) {
    if (!Option) {
      return
    }

    // First save the users option choice in the log
    if (Option.text) {
      this.setState(state => {
        const history = state.history.concat(
          [
            {
              text: state.current_prompt.morpheus_text,
              user: ':root system_admin$ ',
            },
          ],
          [{ text: Option.text, user: `:root ${this.state.username}$ ` }]
        )
        return {
          history,
          show_options: false,
          show_morpheus: false,
        }
      })
    }

    this.setState({
      typingIndicator: true,
      current_prompt: storyline[Option.storyline_id],
    })

    // Set a random amount of time for morpheus to respond so that his response is not unrealistically instant
    setTimeout(() => {
      this.setState({
        show_morpheus: true,
        typingIndicator: false,
      })
    }, Math.random() * 4500 + 500)
  }

  // Count backwards from this.state.timer
  tick() {
    setTimeout(() => {
      if (this.state.timer <= 0) {
        console.log('close')
      }
      this.setState(state => {
        return {
          timer: state.timer - 1,
        }
      })
    }, 1000)
  }

  // Start count down then close the terminal
  startCountdown() {
    if (this.state.timer > 0) {
      this.tick()
    } else {
      this.closeMatrixTerminal(true)
    }
  }

  render() {
    // Display previous text
    let log = this.state.history.map((text, i) => {
      return (
        <div
          className={
            'ng-matrix-log' +
            (text.user == ':root system_admin$ ' ? ' ng-matrix-log-admin' : '')
          }
          key={i}
        >
          <span className="ng-system-text">{text.user}</span>
          {text.text}
          {i === this.state.history.length - 1 && !this.state.show_morpheus ? (
            <Typed strings={['']} typeSpeed={60} />
          ) : null}
        </div>
      )
    })
    // Display user options
    let options = null
    if (this.state.show_options && this.state.current_prompt.options) {
      options = this.state.current_prompt.options.map((option, i) => {
        return (
          <div
            className="ng-option"
            onClick={this.selectOption.bind(this, i)}
            key={i}
          >
            <a className="ng-option-choice">
              {i == 0
                ? 'A) '
                : i == 1
                ? 'B) '
                : i == 2
                ? 'C) '
                : i == 3
                ? 'D) '
                : 'E) '}{' '}
              <div className="ng-option-arrow"> >> </div>
              {option.text}
            </a>
          </div>
        )
      })
    } else if (this.state.show_options && !this.state.current_prompt.options) {
      options = (
        <div>
          <div className="ng-option-countdown">
            The Matrix will close in <span id="timer">{this.state.timer}</span>{' '}
            seconds
          </div>
        </div>
      )
      this.startCountdown()
    }
    return (
      <MatrixRain>
        <div className="ng-matrix-program-container-outer">
          <div
            className={
              'ng-matrix-program-container ng-flicker-container' +
              (this.state.show_terminal
                ? ''
                : ' ng-matrix-program-container-hide')
            }
          >
            <div className="ng-window-top">
              <div className="ng-window-buttons-container">
                <a
                  className="ng-window-button closebutton"
                  onClick={() => this.closeMatrixTerminal(true)}
                >
                  <div>x</div>
                </a>
                <a
                  className="ng-window-button minimizebutton"
                  onClick={() => this.closeMatrixTerminal(false)}
                >
                  <div>&ndash;</div>
                </a>
                <a
                  className="ng-window-button zoombutton"
                  onClick={() => this.makeMatrixTerminalFullscreen()}
                >
                  <div>+</div>
                </a>
              </div>
              <div className="ng-window-title">C:/Restricted_Terminal.exe</div>
            </div>
            <div className="ng-scrollable-window-container">
              <div className="ng-scrollable-window-inner">
                {this.state.show_options ? (
                  <div className="ng-options-container">
                    <span className="ng-options-username">
                      :root {this.state.username}$
                    </span>
                    <div className="ng-options-block">
                      {this.state.current_prompt.options ? (
                        <span id="blink">Click or press a key to respond</span>
                      ) : null}
                      {options}
                    </div>
                  </div>
                ) : null}
                <div className="ng-matrix-prompt-container">
                  {this.state.typingIndicator ? (
                    <div className="ng-matrix-spinner" />
                  ) : null}
                  <div
                    className={
                      'ng-morpheus ' +
                      (this.state.show_morpheus ? '' : 'ng-hide-morpheus')
                    }
                  >
                    {this.state.show_morpheus ? (
                      <>
                        <span className="ng-system-text">
                          :root system_admin$&nbsp;
                        </span>
                        <Typed
                          onComplete={() => {
                            this.onCompleteTyped()
                          }}
                          strings={[this.state.current_prompt.morpheus_text]}
                          typeSpeed={60}
                        />
                      </>
                    ) : null}
                  </div>
                </div>
                {log.reverse()}
              </div>
            </div>
          </div>
          <div className="ng-matrix-program-button-credits">
            Tyler Holden, John Aucar & Devion Villegas
            <br />
            Created at NextGen Code Company&nbsp;&copy;
          </div>
          <div
            className={
              'ng-matrix-program-button-container ' +
              (this.state.show_terminal
                ? ''
                : 'ng-matrix-program-button-container-show')
            }
          >
            <a
              className="ng-matrix-program-button"
              onClick={() => this.openMatrixTerminal()}
            >
              ACCESS MATRIX TERMINAL
            </a>
          </div>
        </div>
      </MatrixRain>
    )
  }
}

export default MatrixProgram
