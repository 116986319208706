const storyline = {
  reset: {
    options: [
      {
        storyline_id: '0',
      },
    ],
  },
  '0': {
    morpheus_text: 'Wake up, Neo... The Matrix has you...',
    options: [
      {
        text: 'I never thought the day would come...',
        storyline_id: '2',
      },
      {
        text: 'The Matrix? Who is this?',
        storyline_id: '7',
      },
      {
        text: 'What is this? Am I being Hacked?',
        storyline_id: '1',
      },
    ],
  },
  '1': {
    morpheus_text:
      'This is a computer program that tunnels through the matrix via a backdoor. I can communicate with you from the real world. The one you have never seen.',
    options: [
      {
        text: 'Who are you?',
        storyline_id: '7',
      },
      {
        text: 'The real world?',
        storyline_id: '3',
      },
      {
        text: 'Am I being hacked? Time to call tech support.',
        storyline_id: '5',
      },
    ],
  },
  '2': {
    morpheus_text:
      'I wish I could leave you in ignorance for the real world is not a place for the weak.',
    options: [
      {
        text: 'I know but I want to visit Zion!',
        storyline_id: '4',
      },
      {
        text: 'Is this Neo?',
        storyline_id: '6',
      },
      {
        text: 'Who are you?',
        storyline_id: '7',
      },
    ],
  },
  '3': {
    morpheus_text:
      'The real time is actually somewhere near 2199. We lost track of time after we scorched the sky. You have never seen the real world. You are living in the Matrix. If you want out, I can help you.',
    options: [
      {
        text: 'Who is this?',
        storyline_id: '7',
      },
      {
        text: 'I want out. Tell me what to do.',
        storyline_id: '8',
      },
      {
        text: "I don't know what the Matrix is...",
        storyline_id: '28',
      },
    ],
  },
  '4': {
    morpheus_text:
      'In good time. We must make sure the timing of your arrival is right.', // links to 6
    options: [
      {
        text: 'Trinity, is that you?',
        storyline_id: '7',
      },
      {
        text: 'Who is this?',
        storyline_id: '7',
      },
    ],
  },
  '5': {
    morpheus_text:
      'No you are not being hacked. Your whole life is a lie. What you think the world looks like around you, is merely a computer program designed to keep your mind running but nothing more.',
    options: [
      {
        text: "I'm so confused. I've never seen the matrix...",
        storyline_id: '28',
      },
      {
        text: 'Who are you?',
        storyline_id: '7',
      },
    ],
  },
  '6': {
    morpheus_text:
      'Neo is in the matrix with you right now. He is searching for something. I cannot tell you any more than that.', // Linked from 4
    options: [
      {
        text: 'Trinity, is that you?',
        storyline_id: '7',
      },
      {
        text: 'Who is this?',
        storyline_id: '7',
      },
    ],
  },
  '7': {
    morpheus_text: 'My name is Morpheus',
    options: [
      {
        text: 'Sup Bro',
        storyline_id: '8',
      },
      {
        text: 'What do you want, Morpheus?',
        storyline_id: '8',
      },
      {
        text:
          "I don't know who Morpheous is. And I don't know what the Matrix is...",
        storyline_id: '28',
      },
    ],
  },
  '8': {
    morpheus_text: 'I need you to watch out for Agents',
    options: [
      {
        text: 'Agents?',
        storyline_id: '9',
      },
      {
        text: 'I can do that. Am I in danger?',
        storyline_id: '10',
      },
      {
        text: 'What do I do if I see some?',
        storyline_id: '11',
      },
    ],
  },
  '9': {
    morpheus_text:
      'Yes. They go by the last name of Smith. Typically dressed in a black suit and they always show up in groups.',
    options: [
      {
        text: 'Okay. What do I do if I see them?',
        storyline_id: '11',
      },
    ],
  },
  '10': {
    morpheus_text: 'You are not in danger. The Agents do not know about you.',
    options: [
      {
        text: 'Okay. What do I do if I see them?',
        storyline_id: '11',
      },
    ],
  },
  '11': {
    morpheus_text:
      'Tell Tango. He will be our common point of communication. He was the one that brought you to me.',
    options: [
      {
        text: 'Why should I?',
        storyline_id: '12',
      },
      {
        text: 'How do I know I can trust Tango?',
        storyline_id: '13',
      },
      {
        text: 'How do I get in contact with Tango?',
        storyline_id: '14',
      },
    ],
  },
  '12': {
    morpheus_text:
      'The survival of Zion depends on our network of informants inside the Matrix. I need you to stay on the inside for now...',
    options: [
      {
        text: 'How do I know I can trust Tango?',
        storyline_id: '15',
      },
      {
        text: 'Who is Tango?',
        storyline_id: '13',
      },
      {
        text: 'How do I reach Tango?',
        storyline_id: '14',
      },
    ],
  },
  '13': {
    morpheus_text:
      'Tango is an intelligent internet species similar to the machines. He lives in the Matrix but cannot be identified as an object in the Matrix since he is simply another program running inside of the Matrix.',
    options: [
      {
        text: 'Who created Tango?',
        storyline_id: '15',
      },
      {
        text: 'How do we know Tango is not working for the Architect?',
        storyline_id: '16',
      },
      {
        text: 'How do I reach Tango?',
        storyline_id: '14',
      },
    ],
  },
  '14': {
    morpheus_text:
      'You can reach Tango via email or via chat. I believe he has hosted this access on the website of NextGen Code Company. This is an important task for ensuring the safety of the Human race. Can I count on you?',
    options: [
      {
        text: 'Yes, but who created Tango?',
        storyline_id: '15',
      },
      {
        text: 'Yes, but how do we know Tango is not working for the Architect?',
        storyline_id: '16',
      },
      {
        text:
          "No. Unfortunately I'm conspiring with Cypher and the Smiths for an upgraded lifestyle in the Matrix.",
        storyline_id: '27',
      },
    ],
  },
  '15': {
    morpheus_text:
      'Tango was created by the NextGen Team... However, Tango is incapable of harming humans, farming them or otherwise disobeying the goal of human survival. Tango is on our side fighting against the machines.',
    options: [
      {
        text: 'Is everything in the Matrix just a program?',
        storyline_id: '17',
      },
      {
        text: 'What if Agents get to Tango first?',
        storyline_id: '16',
      },
    ],
  },
  '16': {
    morpheus_text:
      "Tango's source code is unreadable by the Matrix's core compiler. Thus he cannot interact directly with anyone trying to access his program without Zion's mainframe codes.",
    options: [
      {
        text: 'The first Matrix movie was the best.',
        storyline_id: '20',
      },
      {
        text: "What are Zion's mainframe codes?",
        storyline_id: '18',
      },
      {
        text: 'I want to talk to Neo.',
        storyline_id: '19',
      },
    ],
  },
  '17': {
    morpheus_text:
      'The people are not programs, in fact the machines use humans because they are like batteries, not programs. Although, it is dificult to tell what is real from inside the Matrix.',
    options: [
      {
        text: "I don't think that you are real.",
        storyline_id: '20',
      },
      {
        text: 'Wow, this is all so crazy!',
        storyline_id: '21',
      },
    ],
  },
  '18': {
    morpheus_text: 'I would never give you them.',
    options: [
      {
        text: 'It was worth a shot.',
        storyline_id: '20',
      },
      {
        text: "I don't understand how your glasses always stay on",
        storyline_id: '21',
      },
      {
        text:
          "I don't like this. Keep your mainframe codes and leave me out of whatever this is.",
        storyline_id: '22',
      },
    ],
  },
  '19': {
    morpheus_text: 'Neo is busy.',
    options: [
      {
        text: 'Okay, can I have a red pill now?',
        storyline_id: '24',
      },
      {
        text: 'But I really want to see Neo.',
        storyline_id: '21',
      },
      {
        text: "I don't like this. Give me the blue pill.",
        storyline_id: '22',
      },
    ],
  },
  '20': {
    morpheus_text: 'This is not a game. The Matrix is real.',
    options: [
      {
        text: 'Okay then, can I have a red pill?',
        storyline_id: '24',
      },
      {
        text: "I don't understand how your glasses always stay on",
        storyline_id: '21',
      },
      {
        text: "I don't like this. Give me the blue pill.",
        storyline_id: '22',
      },
    ],
  },
  '21': {
    morpheus_text:
      'Let it all go. Free your mind. What is real? How do you define real?',
    options: [
      {
        text: "I don't know, Morpheus, how do you define real?",
        storyline_id: '23',
      },
      {
        text:
          'I see that the spoon is not really there. I want out of the Matrix.',
        storyline_id: '24',
      },
      {
        text: 'This is dumb',
        storyline_id: '22',
      },
    ],
  },
  '22': {
    morpheus_text:
      'The Matrix is the world that has been pulled over your eyes to blind you from the truth. Do you want to continue living without the truth?',
    options: [
      {
        text: 'No, show me what is real, Morpheus.',
        storyline_id: '23',
      },
      {
        text: "Yes I just don't believe the Matrix is real.",
        storyline_id: '26',
      },
      {
        text: 'Yes, This is dumb.',
        storyline_id: '26',
      },
    ],
  },
  '23': {
    morpheus_text:
      'If real is what you can feel, smell, taste and see, then real is simply electrical signals interpreted by your brain.',
    options: [
      {
        text: "Fine, I'll take the red pill. Geez",
        storyline_id: '24',
      },
      {
        text: 'So you are saying that none of this is real?',
        storyline_id: '24',
      },
      {
        text:
          "That sounds pretty real to me. I think I'll stay here in the Matrix.",
        storyline_id: '26',
      },
    ],
  },
  '24': {
    morpheus_text:
      "I'm trying to free your mind. But I can only show you the door. You're the one that has to walk through it. When the time is right, I can help you get out.",
    options: [
      {
        text: 'Sounds good to me',
        storyline_id: '25',
      },
    ],
  },
  '25': {
    morpheus_text:
      'Neo is in trouble. I must go. Stay alert for Agent activity and report it to Tango on the NextGen website.',
  },
  '26': {
    morpheus_text:
      '"If you tell a big enough lie and tell it frequently enough, it will be believed" - Adolf Hitler',
  },
  '27': {
    morpheus_text: 'We are done here. You will be found.',
  },
  '28': {
    morpheus_text:
      'Then our journey ends here. Unfortunately, no one can be told what the Matrix is. You have to see it for yourself. The Wachowski Brothers have been able to document the Matrix for your viewing.',
  },
}

export default storyline
