import React from 'react'
import Layout from '../components/layout'

import Helmet from 'react-helmet'
import MatrixProgram from '../components/MatrixProgram'

const matrix = () => (
  <Layout>
    <Helmet
      title="Matrix Access | NEXTGEN Code Company"
      meta={[{ name: 'description', content: 'Software development agency.' }]}
    />
    <MatrixProgram />
  </Layout>
)

export default matrix
