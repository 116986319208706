import React, { Component } from 'react'

var c
var ctx
var payload
var font_size = 10
var drops = []

function init() {
  c = document.getElementById('canvas')
  ctx = c.getContext('2d')
  c.height = window.innerHeight
  c.width = window.innerWidth
  ctx.fillStyle = 'rgba(0, 0, 0)'
  ctx.fillRect(0, 0, c.width, c.height)
  payload = 'абвгдеёжзийклмнопрстуфхцчщъыэюя'.split('')
  var columns = c.width / font_size
  for (var x = 0; x < columns; x++) {
    drops[x] = 1
  }

  setInterval(draw, 60)
}

function draw() {
  ctx.fillStyle = 'rgba(0, 0, 0, 0.05)'
  ctx.fillRect(0, 0, c.width, c.height)
  // let rn = Math.floor(Math.random() * 255)
  ctx.fillStyle = '#1cb958'
  ctx.font = font_size + 'px menlo'
  const drops_length = drops.length
  for (var i = 0; i < drops_length; i++) {
    var text = payload[Math.floor(Math.random() * payload.length)]

    ctx.fillText(text, i * font_size, drops[i] * font_size)

    if (drops[i] * font_size > c.height && Math.random() > 0.975) drops[i] = 0

    //incrementing Y coordinate
    drops[i]++
  }
}

class MatrixRain extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    window.addEventListener('load', function() {
      init()
    })
  }

  componentWillUnmount() {}

  render() {
    // Make it rain
    return (
      <>
        {this.props.children}
        <canvas id="canvas" className="ng-matrix-rain"></canvas>
      </>
    )
  }
}

export default MatrixRain
